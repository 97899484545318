import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section class="tj-footer">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="about-widget widget">
                <h3>About Nagho</h3>
                <p>
                  Search for will uncover many web sites variables onto of
                  passages of lorem ipsum available but the majority the words
                  all predefined humour to met chunks recently with desktop.
                </p>
                <ul class="fsocial-links">
                  {process.env.REACT_APP_FACEBOOK_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                  )}
                  {process.env.REACT_APP_TWITTER_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_TWITTER_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                  )}

                  {process.env.REACT_APP_LINKED_IN_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_LINKED_IN_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  )}

                  {process.env.REACT_APP_PINTEREST_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_PINTEREST_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  )}
                  {process.env.REACT_APP_INSTRAGRAM_URL && (
                    <li>
                      <a
                        href={process.env.REACT_APP_INSTRAGRAM_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div class="col-md-6">
              <div class="contact-info widget">
                <h3>Contact Info</h3>
                <ul class="contact-box">
                  <li>
                    <i class="fas fa-home" aria-hidden="true"></i>{" "}
                    {process.env.REACT_APP_BUSINESS_ADDRESS}
                  </li>
                  <li>
                    <i class="far fa-envelope-open"></i>
                    <a href={`mailto:${process.env.REACT_APP_BUSINESS_EMAIL}`}>
                      {process.env.REACT_APP_BUSINESS_EMAIL}
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-phone-square"></i>
                    <a
                      href={`tel:${process.env.REACT_APP_PHONE.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      {process.env.REACT_APP_PHONE}
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-globe-asia"></i>
                    <a
                      href="https://www.nagho.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.nagho.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Footer Content End--> */}
      {/* <!--Footer Copyright Start--> */}
      <section class="tj-copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <p>
                &copy; Copyrights 2024{" "}
                <a
                  href="https://www.nagho.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.nagho.com
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <p class="col-md-6 col-sm-6 text-right">
              <Link to="/privacy-policy">Privacy Policy</Link>{" | "}
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
