import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="tj-inner-banner">
        <div className="container">
          <h2>Privacy Policy</h2>
        </div>
      </div>

      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">Privacy Policy</li>
          </ul>
        </div>
      </div>
      {/* <!--Breadcrumb Section End-->

    <!--About Facts Section Start--> */}
      <section className="tj-aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tj-contact-section">
                <div className="tj-heading-style">
                  <h3>Privacy Policy</h3>
                </div>
                <div className="col-lg-12 col-md-12">
                  <ol style={{ paddingLeft: "0" }}>
                    <li>
                      <b>INTRODUCTION</b>
                      <ul>
                        <li>
                          This document sets out the privacy policy of Nagho
                        </li>
                        <li>
                          We take our privacy obligations seriously and we’ve
                          created this privacy policy to explain how we store,
                          maintain, use and disclose personal information.
                        </li>
                        <li>
                          By providing personal information to us, you consent
                          to our storage, maintenance, use and disclosure of
                          personal information in accordance with this privacy
                          policy.
                        </li>
                        <li>
                          We may change this privacy policy from time to time by
                          posting an updated copy on our website and we
                          encourage you to check our website regularly to ensure
                          that you are aware of our most current privacy policy.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>TYPES OF PERSONAL INFORMATION WE COLLECT</b>
                      <ul>
                        <li>
                          The personal information we collect may include the
                          following:
                          <ul style={{ listStyleType: "disc" }}>
                            <li>name;</li>
                            <li>
                              user's image for profile image from camera or
                              storage
                            </li>
                            <li>mailing or street address;</li>
                            <li>email address;</li>
                            <li>social media information;</li>
                            <li>telephone number and other contact details;</li>
                            <li>age;</li>
                            <li>date of birth;</li>
                            <li>credit card or other payment information;</li>
                            <li>
                              sensitive information as set out below (including
                              criminal records);
                            </li>
                            <li>geo-location information;</li>
                            <li>
                              identity documents such as your partner’s licence;
                            </li>
                            <li>
                              information about your business or personal
                              circumstances;
                            </li>
                            <li>
                              information in connection with client surveys,
                              questionnaires and promotions;
                            </li>
                            <li>
                              your device identity and type, I.P. address,
                              geo-location information, page view statistics,
                              advertising data and standard web log information;
                            </li>
                            <li>information about third parties; and</li>
                            <li>
                              any other information provided by you to us via
                              our website or our online presence, or otherwise
                              required by us or provided by you.
                            </li>
                            <li>
                              Contact List: We have SOS feature in our app and
                              we give user option to pick 3 contacts from
                              contacts list. user can send SOS request to these
                              contacts in case of emergency.{" "}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li className="pt-20">
                      <b>HOW WE COLLECT PERSONAL INFORMATION</b>
                      <ul>
                        <li>
                          We may collect personal information either directly
                          from you, or from third parties, including where you:
                          <ul style={{ listStyleType: "disc" }}>
                            <li>
                              contact us through our website{" "}
                              <a href="https://www.nagho.com">www.nagho.com</a>;
                            </li>
                            <li>
                              use our services or otherwise receive services
                              from us;
                            </li>
                            <li>download our application;</li>
                            <li>submit any of our online sign up forms;</li>
                            <li>
                              communicate with us via email, telephone, SMS,
                              social applications (such as LinkedIn, Facebook or
                              Twitter) or otherwise;
                            </li>
                            <li>
                              interact with our website, social applications,
                              services, content and advertising; and
                            </li>
                            <li>
                              invest in our business or enquire as to a
                              potential purchase in our business.
                            </li>
                          </ul>
                        </li>
                        <li>
                          We may also collect personal information from you when
                          you use or access our website or our social media
                          pages. This may be done through use of web analytics
                          tools, ‘cookies’ or other similar tracking
                          technologies that allow us to track and analyse your
                          website usage. Cookies are small files that store
                          information on your computer, mobile phone or other
                          device and enable and allow the creator of the cookie
                          to identify when you visit different websites. If you
                          do not wish information to be stored as a cookie, you
                          can disable cookies in your web browser.
                        </li>
                        <li>
                          We may use Google Analytics to collect and process
                          data, including when you use third party websites or
                          apps. To find out more see{" "}
                          <a href="https://www.google.com/policies/privacy/partners/">
                            How Google uses data when you use our partners’
                            sites or apps
                          </a>
                          .
                        </li>
                        <li className="pt-20">
                          <strong>USER'S IMAGE</strong>
                          <ul style={{ listStyleType: "disc" }}>
                            <li>
                              We collect and use images you provide for the
                              following purposes:
                            </li>
                            <li>Profile Picture:</li>
                            <li>
                              We collect and display the profile picture you
                              upload within the App for user identification and
                              enhanced user experience.
                            </li>
                            <li>Document Verification:</li>
                            <li>
                              In certain cases, we may require you to submit
                              images of documents for verification purposes. We
                              use these images solely for verifying your
                              identity or fulfilling legal/regulatory
                              requirements.
                            </li>
                            <li>
                              We take reasonable measures to protect your images
                              from unauthorized access, alteration, or
                              destruction.
                            </li>
                            <li>
                              The App is not intended for individuals under 18.
                              We do not knowingly collect information from
                              children under 18.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>With other Users</b>
                      <ul style={{ listStyleType: "disc" }}>
                        <li>
                          We share certain information about Users to enable the
                          scheduling and provision of jobs. This includes
                          sharing:
                        </li>
                        <li>
                          Customer information shared with partner includes name
                          and photo, rating, locations, number of jobs, offered
                          cost, comments and contact information.
                        </li>
                        <li>
                          partner information shared with Customer includes name
                          and photo, information about the vehicle and its
                          photo, location before and during the job, ratings and
                          reviews, number of jobs, contact information.
                        </li>
                        <li>
                          We may also be required to provide Customers with
                          receipts and invoices containing information such as
                          the origin and destination of the job, the total
                          duration and distance of the job, itemization of the
                          total cost, partners name and taxation identifier
                          number. We also include other information on those
                          receipts if required by law.
                          <br />
                          For the purposes of other services, we will share the
                          information with other Users that is necessary for
                          scheduling and provision of such services.
                        </li>
                      </ul>
                    </li>

                    <li className="pt-20">
                      <b>USE OF YOUR PERSONAL INFORMATION</b>
                      <ul style={{ listStyleType: "decimal" }}>
                        <li>
                          We collect and use personal information for the
                          following purposes:
                          <ul style={{ listStyleType: "disc" }}>
                            <li>
                              to provide goods, services or information to you;
                            </li>
                            <li>
                              to store your identity documents for verification
                              purposes;
                            </li>
                            <li>
                              for safety purposes and in case of an emergency;
                            </li>
                            <li>
                              for record-keeping and administrative purposes;
                            </li>
                            <li>
                              to provide information about you to our
                              contractors, employees, consultants, agents or
                              other third parties for the purpose of providing
                              goods or services to you;
                            </li>
                            <li>
                              to improve and optimize our service offering and
                              customer experience;
                            </li>
                            <li>
                              to comply with our legal obligations, resolve
                              disputes or enforce our agreements with third
                              parties;
                            </li>
                            <li>
                              to send you marketing and promotional messages and
                              other information that may be of interest to you
                              and for the purpose of direct marketing (in
                              accordance with the Spam Act). In this regard, we
                              may use email, SMS, social media or mail to send
                              you direct marketing communications. You can
                              opt-out of receiving marketing materials from us
                              by using the opt-out facility provided (e.g. an
                              unsubscribe link);
                            </li>
                            <li>
                              to send you administrative messages, reminders,
                              notices, updates, security alerts, and other
                              information requested by you; and
                            </li>
                            <li>
                              to consider an application of employment from you.
                            </li>
                          </ul>
                        </li>
                        <li>
                          We may disclose your personal information to
                          cloud-providers, contractors and other third parties
                          located inside or outside of Australia. If we do so,
                          we will take reasonable steps to ensure that any
                          overseas recipient deals with such personal
                          information in a manner consistent with how we deal
                          with it.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>LOCATION DATA</b>
                      <ul style={{ listStyleType: "disc" }}>
                        <li>
                          We may collect location data of the Vehicle you use
                          through our services (<strong>Vehicle</strong>).
                        </li>
                        <li>
                          We collect this location data in order to provide you
                          with our services, including to inform you of where a
                          Vehicle is located, to track kilometre use, to provide
                          information to insurance providers and/or in case of
                          an emergency.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>SENSITIVE INFORMATION</b>
                      <ul>
                        <li>
                          We may collect sensitive information about you during
                          the course of providing you services. The type of
                          sensitive information we may collect includes your
                          criminal records.
                        </li>
                        <li>
                          We will only collect this sensitive information where
                          you consent and provide us with this information. If
                          you consent, your sensitive information may only be
                          used and disclosed for purposes relating to providing
                          you with our services.
                        </li>
                        <li>
                          Your sensitive information may also be used or
                          disclosed to comply with our legal obligations.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>SECURITY</b>
                      <ul>
                        <li>
                          We take reasonable steps to ensure your personal
                          information is secure and protected from misuse or
                          unauthorized access. Our information technology
                          systems are password protected, and we use a range of
                          administrative and technical measures to protect these
                          systems. However, we cannot guarantee the security of
                          your personal information.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>LINKS</b>
                      <ul>
                        <li>
                          Our website may contain links to other websites. Those
                          links are provided for convenience and may not remain
                          current or be maintained. We are not responsible for
                          the privacy practices of those linked websites and we
                          suggest you review the privacy policies of those
                          websites before using them.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>
                        REQUESTING ACCESS OR CORRECTING YOUR PERSONAL
                        INFORMATION
                      </b>
                      <ul>
                        <li>
                          If you wish to request access to the personal
                          information we hold about you, please contact us using
                          the contact details set out below including your name
                          and contact details. We may need to verify your
                          identity before providing you with your personal
                          information. In some cases, we may be unable to
                          provide you with access to all your personal
                          information and where this occurs, we will explain
                          why. We will deal with all requests for access to
                          personal information within a reasonable timeframe.
                        </li>
                        <li>
                          If you think that any personal information we hold
                          about you is inaccurate, please contact us using the
                          contact details set out below and we will take
                          reasonable steps to ensure that it is corrected.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>COMPLAINTS</b>
                      <ul>
                        <li>
                          If you wish to complain about how we handle your
                          personal information held by us, please contact us
                          using the details set out below including your name
                          and contact details. We will investigate your
                          complaint promptly and respond to you within a
                          reasonable timeframe.
                        </li>
                      </ul>
                    </li>
                    <li className="pt-20">
                      <b>CONTACT US</b>
                      <ul>
                        <li>
                          For further information about our privacy policy or
                          practices, or to access or correct your personal
                          information, or make a complaint, please contact us
                          using the details set out below:
                        </li>
                        <li>
                          <strong>Email</strong>:{" "}
                          <a href="mailto:reachus@nagho.com">
                            reachus@nagho.com
                          </a>
                        </li>
                        <li>
                          Our privacy policy was last updated on 07th Feb 2024.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tj-cal-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="/images/cta-icon1.png" alt="" />
                <div className="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="/images/cta-icon2.png" alt="" />
                <div className="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="/images/cta-icon3.png" alt="" />
                <div className="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
