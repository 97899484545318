import Home from "./components/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import Service from "./components/service/Service";
import Register from "./components/register/Register";
import Login from "./components/login/Login";
import Profile from "./components/profile/Profile";
import Booknow from "./components/booking/Booknow";
import ChangePassword from "./components/profile/ChangePassword";
import ContactUs from "./components/contactus/ContactUs";
import ForgetPassword from "./components/login/ForgetPassword";
import Error from "./common/Error";
import Confrim from "./components/booking/ConfriomBooking";
import LoginWithPhone from "./components/login/LoginWithPhone";
import Verify from "./components/login/Verifyotp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HiremeBooknow from "./components/booking/HiremeBooknow";
import Payment from "./components/payment/Payment";
import SuccessfulPaymentPage from "./components/payment/SuccessfulPaymentPage";
import PaymentFailedPage from "./components/payment/PaymentFailedPage";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./components/terms-and-conditions/TermsAndConditions";
import ScrollToTop from "./common/ScrollToTop";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="services" element={<Service />} />
          <Route path="register" element={<Register />} />
          {process.env.REACT_APP_LOGIN_TYPE === "email" ? (
            <Route path="login" element={<Login />} />
          ) : (
            <Route path="login" element={<LoginWithPhone />} />
          )}
          <Route path="profile" element={<Profile />} />
          <Route path="payment" element={<Payment />} />
          <Route path="paymentFailedPage" element={<PaymentFailedPage />} />
          <Route
            path="successfulPaymentPage"
            element={<SuccessfulPaymentPage />}
          />
          <Route path="booknow" element={<Booknow />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="*" element={<Error />} />
          <Route path="/confrim" element={<Confrim />} />
          {/* <Route path='/verify' element={<Verify/>} /> */}
          <Route path="/reserve" element={<HiremeBooknow />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
