import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Register from "./Register";
import { loginWithPhoneSchema } from "../../schema";
import Verify from "./Verifyotp";

const LoginWithPhone = () => {
  let tabtype = "phoneLogin";
  var otpval = "";
  const [type, settype] = useState("phoneLogin");
  const [show, setshow] = useState(true);
  const [country, setcountry] = useState([]);
  const [msg, setmsg] = useState("");
  const [otp, setotp] = useState("");
  const [res, setres] = useState([]);

  let navigate = useNavigate();
  const handletab = (value) => {
    if ((tabtype = value)) {
      settype(tabtype);
    }
  };

  const initialValues = {
    country: "AF+93 Afghanistan",
    phone_no: "",
  };
  const handlehide = () => {
    setshow(false);
  };

  useEffect(() => {
    countryData();
  }, []);

  const countryData = () => {
    fetch(`${process.env.REACT_APP_URL}/constantapi/getcountries`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log("----res:", result);
        if (result.code === 200) {
          setcountry(result.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginWithPhoneSchema,
      onSubmit: (values, action) => {
        loginWithPhoneApi();
      },
    });

  const loginWithPhoneApi = () => {
    let data = {
      c_code: values.country.replace(/\D/g, ""),
      u_phone: values.phone_no,
    };
    fetch(`${process.env.REACT_APP_URL}/userapi/phlogin?`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json().then((result) => {
        if (res.status == 200) {
          setres(result);
          handleGenerate();
          handlehide();
        } else {
          setmsg(result.message);
        }
      });
    });
  };
  useEffect(() => {
    verifyOtpApi();
  }, []);

  const verifyOtpApi = () => {
    let data = {
      ckey: "otp_off",
    };
    fetch(`${process.env.REACT_APP_URL}/constantapi/getconstants?`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        sessionStorage.setItem("cvalue", result.response[0].cvalue);
        // otpvalidation()
      });
  };

  const selectHandleChange = (e) => {
    handleChange(e.target.value.replace(/\D/g, ""));
  };

  const handleGenerate = () => {
    var range = "0123456789";

    for (var i = 0; i < 4; i++) {
      otpval += range[Math.floor(Math.random() * 10)];
    }
    setotp(otpval);
    sessionStorage.setItem("otp", otpval);
  };

  return (
    <>
      <Header />
      <div class="tj-inner-banner">
        <div class="container">
          <h2>Login</h2>
        </div>
      </div>

      <div class="tj-breadcrumb">
        <div class="container">
          <ul class="breadcrumb-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li class="active">Login</li>
          </ul>
        </div>
      </div>

      <section class="tj-login">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="tj-tabs">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="active" id="phoneLogin">
                    <a
                      href="#"
                      data-toggle="tab"
                      value={type}
                      onClick={() => handletab("phoneLogin")}
                    >
                      Login
                    </a>
                  </li>
                  <li id="register">
                    <a
                      href="#"
                      data-toggle="tab"
                      value={type}
                      onClick={() => handletab("register")}
                    >
                      Register
                    </a>
                  </li>
                </ul>
              </div>

              {type === "phoneLogin" && (
                <div class="tab-content">
                  <div class="tab-pane active" id="login">
                    <div class="col-md-6 col-sm-6">
                      <div class="login-cta">
                        <ul class="cta-list">
                          <li>
                            <span class="icon-mail-envelope icomoon"></span>
                            <div class="cta-info">
                              <strong>30 Days Money Back Guarantee</strong>
                              <p>
                                A more recently with desktop softy like aldus
                                pages maker still versions have evolved.
                              </p>
                            </div>
                          </li>
                          <li>
                            <span class="icon icon-Headset"></span>
                            <div class="cta-info">
                              <strong>24/7 Customer Support</strong>
                              <p>
                                A more recently with desktop softy like aldus
                                pages maker still versions have evolved.
                              </p>
                            </div>
                          </li>
                          <li>
                            <span class="icon-lock icomoon"></span>
                            <div class="cta-info">
                              <strong>100% Secure Payment</strong>
                              <p>
                                A more recently with desktop softy like aldus
                                pages maker still versions have evolved.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {show ? (
                      <div class="col-md-6 col-sm-6">
                        <form
                          onSubmit={handleSubmit}
                          class="phone-login-frm"
                          id="login_form"
                        >
                          {msg ? (
                            <div className="alert alert-success">{msg}</div>
                          ) : (
                            ""
                          )}
                          {/* <div class="field-holder">
                                                <span class="fas fa-language"></span>
                                                <select name="country"
                                                 value={values.country}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 className={errors.country && touched.country ? "input-error" : null}
                                                >
                                                    <option>Country Code</option>
                                                    {country.map((it)=>{
                                                         return(
                                                            <option>{it.name} {it.code}</option>
                                                         )
                                                    })}
                                                   
                                                </select>
                                                {errors.country && touched.country ?
                                                    <p style={{ color: "#e74c3c", fontWeight: "500", fontSize: "15px" }}>{errors.country}</p>
                                                    : null}
                                            </div> */}
                          <div class="field-holder">
                            <span class="fas fa-mobile-alt"></span>
                            <select
                              style={{
                                width: "15%",
                                border: "none",
                                background: "none",
                              }}
                              name="country"
                              value={values.country}
                              onChange={handleChange}
                            >
                              {country.map((it) => {
                                return (
                                  <option>{`${it.iso_code}${it.code} ${it.name}`}</option>
                                );
                              })}
                            </select>
                            <input
                              type="text"
                              name="phone_no"
                              placeholder="Enter Your Phone Number"
                              maxLength={12}
                              value={values.phone_no.replace(/\D/g, "")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.phone_no && touched.phone_no
                                  ? "input-error"
                                  : null
                              }
                            />
                            {errors.phone_no && touched.phone_no ? (
                              <p
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                }}
                              >
                                {errors.phone_no}
                              </p>
                            ) : null}
                            {errors.country && touched.country ? (
                              <p
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                }}
                              >
                                {errors.country}
                              </p>
                            ) : null}
                          </div>
                          {/* <div class="field-holder">
                                                <span class="fas fa-lock"></span>
                                                <input type="text" value={logpswd} name="u_password" onChange={(e) => setlogpswd(e.target.value)} placeholder="Password" />
                                                {err && logpswd.length <= 0 ? <label id="u_email-error" className="error" style={{ color: "#e74c3c", marginBottom: "10px", fontWeight: "500", fontSize: "15px" }}>This field is required</label> : null}

                                            </div> */}
                          {/* <a href="/forgetpassword" class="forget-pass">Forget Password?</a> */}
                          <button type="submit" class="reg-btn">
                            Continue{" "}
                            <i
                              class="fa fa-arrow-circle-right"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </form>
                      </div>
                    ) : (
                      <div class="col-md-6 col-sm-6">
                        <Verify
                          phone={values.phone_no}
                          code={values.country}
                          result={res}
                          ot={otp}
                        />
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              )}

              {type === "register" && <Register />}
            </div>
          </div>
        </div>
      </section>
      {/* <!--Login Section End-->	
			
			<!--Call To Action Content Start--> */}
      <section class="tj-cal-to-action">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="images/cta-icon1.png" alt="" />
                <div class="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="images/cta-icon2.png" alt="" />
                <div class="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="images/cta-icon3.png" alt="" />
                <div class="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LoginWithPhone;
