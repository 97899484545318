import React, { useState } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import { useFormik } from "formik";
import { contactSchema } from "../../schema";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [message, setmessage] = useState(false);
  const [error, seterror] = useState("");
  const [Loading, setLoading] = useState(false);

  const initialValues = {
    full_name: "",
    email: "",
    subject: "",
    message: "",
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: contactSchema,
      onSubmit: (values, action) => {
        messageHandle();
        seterror("");
        action.resetForm();
      },
    });

  const messageHandle = async () => {
    let data = {
      full_name: values.full_name,
      email: values.email,
      subject: values.subject,
      message: values.message,
    };
    await fetch(
      // "https://rentals.stitchmyapp.com/ver5.1/webservices/tw_email/contact.php",
      "https://app.giovoyage.com/webservices/tw_email/contact.php",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then((res) => {
      res.json().then((result) => {
        if (res.code == 200) {
          values.full_name = "";
          setmessage(true);
        } else {
          seterror(result.response);
          setmessage(false);
        }
      });
    });
  };

  return (
    <>
      <Header />
      <div class="tj-inner-banner">
        <div class="container">
          <h2>Contact Us</h2>
        </div>
      </div>
      <div class="tj-breadcrumb">
        <div class="container">
          <ul class="breadcrumb-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li class="active">Contact Us</li>
          </ul>
        </div>
      </div>

      <section class="tj-contact-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="tj-heading-style">
                <h3>Contact Us</h3>
                <p>
                  Lorem Ipsum passages, and more recently with desktop
                  publishing software like aldus pageMaker including versions of
                  all the Lorem Ipsum generators
                </p>
              </div>
            </div>
            <div class="col-md-8 col-sm-8">
              <div class="form-holder">
                {message ? (
                  <div className="alert alert-success">
                    Message Sent Successfully
                  </div>
                ) : (
                  ""
                )}
                {error ? (
                  <div className="alert alert-success">{error}</div>
                ) : (
                  ""
                )}
                <form
                  onSubmit={handleSubmit}
                  class="tj-contact-form"
                  id="contact-form"
                >
                  <div class="row">
                    <div class="col-md-6 col-sm-6">
                      <div class="inner-holder">
                        <label for="name">Name</label>
                        <input
                          placeholder="Enter Your Name"
                          name="full_name"
                          type="text"
                          id="name"
                          value={values.full_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.full_name && touched.full_name
                              ? "input-error"
                              : null
                          }
                        />
                        {errors.full_name && touched.full_name ? (
                          <p
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            {errors.full_name}
                          </p>
                        ) : null}
                        {/* {error && name.length <= 0 ? <label className="error" id="form-error" style={{ color: "#e74c3c" }}>This field is required</label> : null} */}
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 no-pad">
                      <div class="inner-holder">
                        <label for="email">Email</label>
                        <input
                          placeholder="Enter Your Email"
                          name="email"
                          type="text"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email ? "input-error" : null
                          }
                        />
                        {errors.email && touched.email ? (
                          <p
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            {errors.email}
                          </p>
                        ) : null}
                        {/* {error && email.length <= 0 ? <label className="error" id="form-error" style={{ color: "#e74c3c" }}>This field is required</label> : null} */}
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="inner-holder">
                        <label for="subject">Subject</label>
                        <input
                          placeholder="Your Subject"
                          name="subject"
                          type="text"
                          id="subject"
                          value={values.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.subject && touched.subject
                              ? "input-error"
                              : null
                          }
                        />
                        {errors.subject && touched.subject ? (
                          <p
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            {errors.subject}
                          </p>
                        ) : null}
                        {/* {error && sub.length <= 0 ? <label className="error" id="form-error" style={{ color: "#e74c3c" }}>This field is required</label> : null} */}
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="inner-holder">
                        <label for="message">Message</label>
                        <textarea
                          name="message"
                          placeholder="Your Message"
                          id="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.message && touched.message
                              ? "input-error"
                              : null
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <div class="inner-holder">
                        <button
                          class="btn-submit"
                          id="frm_submit_btn"
                          type="submit"
                        >
                          Send Message{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="address-box">
                <div class="add-info ">
                  <span class="icon-map icomoon"></span>
                  <p>{process.env.REACT_APP_BUSINESS_ADDRESS}</p>
                  {/* <p>Hire Me, Orlando,<br /> United States</p> */}
                </div>
                <div class="add-info">
                  <span class="icon-phone icomoon"></span>
                  <p>
                    <a
                      href={`tel:${process.env.REACT_APP_PHONE.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      {process.env.REACT_APP_PHONE}
                    </a>
                  </p>
                </div>
                <div class="add-info">
                  <span class="icon-mail-envelope-open icomoon"></span>
                  <p>
                    <a href={`mailto:${process.env.REACT_APP_BUSINESS_EMAIL}`}>
                      {process.env.REACT_APP_BUSINESS_EMAIL}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Contact Section End-->	
			<!--Call To Action Content Start--> */}
      <section class="tj-cal-to-action">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="/images/cta-icon1.png" alt="" />
                <div class="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="/images/cta-icon2.png" alt="" />
                <div class="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cta-box">
                <img src="/images/cta-icon3.png" alt="" />
                <div class="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default ContactUs;
