import React, { useState, useEffect, useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "../profile/booking.css";
import moment from "moment";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Slider from "react-slick";

const Home = () => {

  let countryid = sessionStorage.getItem("countryid");


  const today = dayjs();
  const futureTime = today.add(15, 'minute');
  const [time, settime] = useState(futureTime);

  const fTime = today.add(15, "minute");
  const [myDate, setMyDate] = useState(fTime);
  const [customGMTtime, setCustomGMTtime] = useState(moment(fTime.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss"));
  // const [customGMTtime, setCustomGMTtime] = useState(myDate);
  
  // var d = new Date(myDate);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss a");
  // console.log("GMTtime......a.b.......",GMTtime);
  // setCustomGMTtime(GMTtime) 
  

  const [drop, setdrop] = useState("");
  const [address, setaddress] = useState("");
  const [citydrop, setcitydrop] = useState([]);
  const [city, setcity] = useState(process.env.REACT_APP_CITY_ID);
  const [category, setcategory] = useState("");
  const [catdata, setcatdata] = useState([]);
  const [select, setselect] = useState();
  const [fullname, setfullname] = useState(sessionStorage.getItem("name"));
  const [phone, setphone] = useState(sessionStorage.getItem("phone"));
  const [email, setemail] = useState(sessionStorage.getItem("email"));
  const [message, setmessage] = useState(false);
  const [err, seterr] = useState(false);
  const [cate, setcate] = useState("");
  const [base, setbase] = useState("");
  const [farekm, setfarekm] = useState("");
  const [faremin, setfaremin] = useState("");
  const [pickuptrip, setpickuptrip] = useState(false);
  const [droptrip, setdroptrip] = useState(false);
  const [droplatlng, setdroplatlng] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [calculateDistance, setCalculateDistance] = useState("");
  const [calculateDirection, setCalculateDirection] = useState("");
  const [serviceTax, setServiceTax] = useState("10");
  const [distance, setDistance] = useState("0.01");
  const [duration, setDuration] = useState("0");
  const [addressValue, setAddressValue] = useState("");
  const [dropValue, setDropValue] = useState("");
  const [filterCateByCity, setFilterCateByCity] = useState("");
  const [catDataForCarousel, setCatDataForCarousel] = useState([]);
  const [sliderCat ,setSliderCat] =useState(null)

  const [BaseInclude, setbaseInclude] = useState(0);


// useEffect(()=>{
//   // window.location.href = "https://giovoyage.com/"; 
//   navigate("/booknow");
// },[])

  
  useEffect(() => {
    countryData();
  }, []);

  const countryData = () => {
    
    // fetch(`https://rentals.stitchmyapp.com/ver5.1/webservices/1.1.3/index.php/constantapi/getcountries`, {
    fetch(`${process.env.REACT_APP_URL}/constantapi/getcountries`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {

      }).catch((error)=>{
        console.error("Error:",error)
      });
  };


  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const dropAutocompleteRef = useRef(null);

  const selectedTime = time.$d
    ? moment(time.$d).seconds(0).milliseconds(0)
    : moment(time).seconds(0).milliseconds(0);
  const currentTime = moment().seconds(0).milliseconds(0);

  let username = sessionStorage.getItem("email");

  const handlesignout = () => {
    let path = "/login";
    navigate(path);
  };
  const apikey = sessionStorage.getItem("key");
  const userid = sessionStorage.getItem("userid");

  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        autocompleteRef?.current &&
        !autocompleteRef?.current?.event?.target?.value &&
        !addressValue
      ) {
        setaddress("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [address, addressValue]);

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropValue
      ) {
        setdrop("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [drop, dropValue]);

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
  };

  const handleClearDropSelection = () => {
    setDropValue("");
    setdrop("");
  };

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
    setcoordinate(l1);
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    setcoord(l2);
    setDropValue(result[0].formatted_address);
    setdrop(value);
  };

  const handleDateAndTime = (e) => {
    setCustomGMTtime( moment(e.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss"))
    setMyDate(e.$d)

    setselect(e.$d);
    settime(e.$d);
  };

  useEffect(() => {
    if (!city) {
      setcategory("");
      setAddressValue("");
      setaddress("");
      setDropValue("");
      setdrop("");
      setcatdata([]);
    }
  }, [city]);

  useEffect(() => {
    citiesapi();
  }, []);

  const citiesapi = () => {
    fetch(`${process.env.REACT_APP_URL}/cityapi/getcities?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcitydrop(data.response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (filterCateByCity) {
      handleCat();
    }
  }, [filterCateByCity]);

  const handleCat = () => {
    let data = {
      city_id: filterCateByCity,
    };
    fetch(`${process.env.REACT_APP_URL}/categoryapi/getcategoriesv1`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcatdata(data.response);
      }).catch((error)=>{
        console.error("Error:",error)
      });
  };

  useEffect(() => {
    handleCatDataForCarousel();
  }, []);

  const handleCatDataForCarousel = () => {
    let data = {
      // city_id: filterCateByCity || "14",
    };
    fetch(`${process.env.REACT_APP_URL}/categoryapi/getcategoriesv1`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCatDataForCarousel(data.response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // let Base_price = base;
  // let Base_fare_per_km = farekm
  // let fare_per_min = faremin
  // let sr = 30;

  // let jtf=(parseInt(Base_price)+((Base_fare_per_km) * (4.7)) + ((fare_per_min) * (22)));
  // let ST = (((parseInt(Base_price)) + (Base_fare_per_km * parseInt(4.7)) + (fare_per_min * parseInt(22))) * (sr / 100)).toFixed(2)
  // let GTF = (jtf + Number(ST)).toFixed(2)
  useEffect(() => {
    changelatlng();
    if (coord.lat.length == "" && coord.lng.length == "") {
      setCalculateDistance(0);
      setCalculateDirection(0);
      setDistance(0);
      setDuration(0);
    }
  }, [address, drop]);

  useEffect(() => {
    contactApi();
  }, [drop]);

  const contactApi = () => {
    if (coord.lat.length != 0 && coord.lng.length != 0) {
      fetch(
        // `https://rentals.stitchmyapp.com/ver5.1/webservices/push/distanceMatrix.php?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          setDistance(result.routes[0].legs[0].distance.text);
          setDuration(result.routes[0].legs[0].duration.text);
          setCalculateDistance(result.routes[0].legs[0].distance.value / 1000);
          setCalculateDirection(result.routes[0].legs[0].duration.value / 60);
          Base_price = base;
          Base_fare_per_km = farekm;
          fare_per_min = faremin;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("message");
    }
  };

  let Base_price = base;
  let Base_fare_per_km = farekm;
  let fare_per_min = faremin;
  let sr = serviceTax;
  let jtf = (
   Number(Base_price) +
    (Base_fare_per_km * (calculateDistance - BaseInclude )+
      fare_per_min * calculateDirection) 
  ).toFixed(2);
  //   parseInt(Base_price) +
  //   (Base_fare_per_km * calculateDistance + fare_per_min * calculateDirection)
  // ).toFixed(2);
  let ST = (((process.env.REACT_APP_SERVICE_TAX*jtf)/100).toFixed(2))
  // (
    // (parseInt(Base_price) +
    //   Base_fare_per_km * calculateDistance +
    //   fare_per_min * calculateDirection) *
    // (sr / 100)
  // ).toFixed(2);
  let GTF = (Number(jtf) + Number(ST)).toFixed(2);
  const changelatlng = () => {
    if (addressValue != pickupAddress) {
      setcoordinate({
        lat: "",
        lng: "",
      });
    }
    if (dropValue != droplatlng) {
      setcoord({
        lat: "",
        lng: "",
      });
    }
    if (coordinate.lat.length != 0 && coordinate.lng.length != 0) {
      setpickuptrip(true);
      setdroptrip(false);
    }
    if (
      coordinate.lat.length != 0 &&
      coordinate.lng.length != 0 &&
      coord.lat.length != 0 &&
      coord.lng.length != 0
    ) {
      setdroptrip(true);
      setpickuptrip(false);
    }
  };

  const selectitem = (e) => {
    setcategory(e);
    catdata.map((res, ind) => {

      if (e == res.category_id) {
        setbaseInclude(res.cat_base_includes);
        setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
      }
    });
  };

  useEffect(()=>{
    // setcity(e);
    setFilterCateByCity(city);
    citydrop.map((res) => {
      setServiceTax(res.city_tax);
    });
  },[])



  // const selectCity = (e) => {
  //   setcity(e);
  //   setFilterCateByCity(e);
  //   citydrop.map((res) => {
  //     setServiceTax(res.city_tax);
  //   });
  // };
  const form = moment(select).format("YYYY-MM-DD");
  const ftime = moment(time).format("HH:mm");
  const time_date = form + " " + ftime;

  // var d = new Date(time_date);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm");
  const cityId =`${process.env.REACT_APP_CITY_ID}`



  // useEffect(()=>{
    // console.log("customGMTtime..a.....",customGMTtime);
    // var d = new Date(customGMTtime);
    // var utc_offset = d.getTimezoneOffset();
    // d.setMinutes(d.getMinutes() + utc_offset);
    // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss a");
    // console.log("GMTtime......a........",GMTtime);
    // setCustomGMTtime(GMTtime) 
  // },[customGMTtime])
  var d = new Date(customGMTtime);
  var utc_offset = d.getTimezoneOffset();
  d.setMinutes(d.getMinutes() + utc_offset);
  const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss A");

  const handleBooknow = (e) => {
    e.preventDefault();
    if (validation()) {
      let data = {
        trip_date: GMTtime,
        cat_name: cate,
        trip_from_loc: address,
        trip_to_loc: drop,
        city_id: process.env.REACT_APP_CITY_ID,
        user_id: userid,
        category_id: category,
        trip_currency: "€",
        trip_base_fare: jtf,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_dunit: "km",
        trip_type: "normal",
        trip_total_time: calculateDirection,
        trip_pay_amount: GTF,
        seats: "1",
        trip_status: "request",
        is_share: "0",
        is_delivery: "0",
        tax_amt: ST,
      };

      fetch(`${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          res.json();
        })
        .then((result) => {
          if (result.status == 200) {
            // navigate("/profile");
            setmessage(true);
            setselect(new Date());
            settime(time);
            setcity("");
            setcategory("");
            setaddress("");
            setdrop("");
            seterr(false);

            sessionStorage.setItem("tab_type", "bookinghistory");

            let path = "/profile";

            setTimeout(() => {
              navigate(path);
            }, [2000]);
          } else {
            console.log(result.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  

  const validation = () => {
    let result = true;
    if (
      address.length == 0 ||
      drop.length == 0 ||
      city.length == 0 ||
      category.length == 0 ||
      time.length == 0 ||
      !selectedTime.isSameOrAfter(currentTime)
    ) {
      result = false;
      seterr(true);
      setmessage(false);
    }
    return result;
  };

  const handleCarouselBookNow = (cat) => {
   const serializedObject = JSON.stringify(cat);

     setSliderCat(cat)
     navigate(`/booknow`,{state:{cat}})
    // localStorage.setItem("carouselCatData", JSON.stringify(cat));
    
  };

  const [errorWithoutCity, setErrorWithoutCity] = useState(false);
  const handleWithoutCityClick = () => {
    if (!city) {
      setErrorWithoutCity(true);
    }
  };

  return (
    <>
      <Header />
      <section class="tj-banner-form">
        <div class="container">
          <div class="row">
            {/* <!--Header Banner Caption Content Start--> */}
            <div class="col-md-8 col-sm-7">
              <div class="banner-caption">
                <div class="banner-inner bounceInLeft animated delay-2s">
                  <strong>
                    More recently with desktop publishing software including
                    versions
                  </strong>
                  <h2>Upto 25% off on first booking through your app</h2>
                  <div class="banner-btns">
                    <a href="https://apps.apple.com/in/app/hireme-taxi-booking-app/id1120548049" class="btn-style-1">
                      <i class="fab fa-apple"></i> Download App
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.rider.hire_me&pcampaignid=web_share" class="btn-style-2">
                      <i class="fab fa-android"></i> Download App
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-5">
              <div className="trip-outer">
                <div class="trip-type-tabs">
                  <ul class="nav nav-tabs"></ul>
                </div>

                {/* <!--Banner Tab Content Start--> */}
                <div className="tab-content">
                  {message ? (
                    <div className="alert alert-success">Your request has been saved 
                    successfully,</div>
                  ) : null}
                  <center style={{ paddingBottom: "10px" }}>
                    <h3>
                      <u>Book Now  </u>
                    </h3>
                  </center>

                  <div class="tab-pane active" id="one-way">
                    {/* <!--Banner Form Content Start--> */}
                    <div class="trip-type-frm padding-input" id="User-Form">
                    <div className="field-outer span-icon">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            // value={time}
                            value={myDate}
                            disablePast = {"false"}
                            ampm
                            onChange={(e) => handleDateAndTime(e)}
                            slotProps={{
                              textField: {
                                error: false,
                                inputProps: { readOnly: true },
                              },
                            }}
                            format="DD/MM/YYYY hh:mm A"
                          />
                        </LocalizationProvider>
                        {err && !selectedTime.isSameOrAfter(currentTime) ? (
                          <label
                            id="point_start_loc-error"
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                            className="error"
                          >
                            Please select a future time
                          </label>
                        ) : null}
                      </div>
                      <div class="field-outer">
                        <PlacesAutocomplete
                          value={address}
                          onChange={onChangeAddress}
                          onSelect={handleSelect}
                          ref={autocompleteRef}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <span className="fas fa-map-marker-alt"></span>
                              <input
                                {...getInputProps({
                                  placeholder: "Pickup Location",
                                  className: "location-search-input inputTextColor",
                                })}
                                readOnly={addressValue}
                                style={{ paddingRight: "56px", }}
                              />
                              {addressValue && (
                                <span
                                  className="fas fa-times-circle"
                                  style={{
                                    marginRight: "14px",
                                    fontSize: "17px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearSelection}
                                ></span>
                              )}
                              <div className="autocomplete-dropdown-container">
                                {/* {loading && <div>Loading...</div>} */}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      className="input-suggestion"
                                      {...getSuggestionItemProps(suggestion, {
                                        //className,
                                        style,
                                      })}
                                    >
                                      <i class="material-icons ">location_on</i>{" "}
                                      <span className="input-type">
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {err && address.length <= 0 ? (
                          <label
                            id="point_start_loc-error"
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                            className="error"
                          >
                            This field is required
                          </label>
                        ) : null}
                      </div>

                      <div class="field-outer">
                        <PlacesAutocomplete
                          value={drop}
                          onChange={setdrop}
                          onSelect={handleDrop}
                          ref={dropAutocompleteRef}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <span className="fas fa-map-marker-alt"></span>
                              <input
                                {...getInputProps({
                                  placeholder: "Drop Location",
                                  className: "location-search-input",
                                })}
                                readOnly={dropValue}
                                style={{ paddingRight: "56px" }}
                              />
                              {dropValue && (
                                <span
                                  className="fas fa-times-circle"
                                  style={{
                                    marginRight: "14px",
                                    fontSize: "17px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleClearDropSelection}
                                ></span>
                              )}
                              <div className="autocomplete-dropdown-container">
                                {/* {loading && <div>Loading...</div>} */}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      className="input-suggestion"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      <i class="material-icons ">location_on</i>{" "}
                                      <span className="input-type">
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {err && drop.length <= 0 ? (
                          <label
                            id="point_start_loc-error"
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                            className="error"
                          >
                            This field is required{" "}
                          </label>
                        ) : (drop && (drop == address)) ? (
                          <label
                          id="point_start_loc-error"
                          style={{
                            color: "#e74c3c",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                          className="error"
                          >
                           Please Select Different Destination Location
                          </label>
                        )  : null}
                      </div>



                      <div class="field-outer">
                        <span class="fa fa-taxi"></span>
                        <select
                          class="form-control"
                          name="category"
                          value={category}
                          onChange={(e) => selectitem(e.target.value)}
                          onFocus={handleWithoutCityClick}
                          id="category_id"
                        >
                          <option value="">Select Category</option>
                          {catdata.map((res) => {
                            return (
                              <>
                                <option value={res.category_id}>
                                  {res.cat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        {err && category.length <= 0 ? (
                          <label
                            id="point_start_loc-error"
                            style={{
                              color: "#e74c3c",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                            className="error"
                          >
                            This field is required
                          </label>
                        ) : null}
                      </div>

              
                      <div class="field-outer">
                        <span class="far fa-user-circle"></span>
                        <input
                          type="text"
                          name="username"
                          value={fullname}
                          onChange={(e) => setfullname(e.target.value)}
                          placeholder="Enter Full Name"
                          readOnly
                        />
                        {/* {err && fullname.length<=0?<label id="point_start_loc-error" style={{color:"#e74c3c", fontWeight:"500",fontSize:"15px"}} className="error">This field is required</label>:null} */}
                      </div>

                      <div class="field-outer">
                        <span class="far fa-envelope"></span>
                        <input
                          type="text"
                          name="username"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Enter Email Address"
                          readOnly
                        />
                        {/* {err && email.length<=0?<label id="point_start_loc-error" style={{color:"#e74c3c", fontWeight:"500",fontSize:"15px"}} className="error">This field is required</label>:null} */}
                      </div>

                      <div class="field-outer">
                        <span class="far fa fa-phone-square"></span>
                        <input
                          type="text"
                          name="username"
                          value={phone?`+${+countryid}  ${phone}`:""} 
                          onChange={(e) => setphone(e.target.value)}
                          placeholder="Enter Phone Number"
                          readOnly
                        />
                        {/* {err && phone.length<=0?<label id="point_start_loc-error" style={{color:"#e74c3c", fontWeight:"500",fontSize:"15px"}} className="error">This field is required</label>:null} */}
                      </div>
                      
                      <div class="field-outer" style={{    background: "beige",
    height: "45px",
    textAlign:"center",
    padding: "12px",
    color: "black",
}}>
                   
                          {/* Book Now{" "} */}
                       <strong>   Estimated Fare: </strong>
                       €{GTF != "NaN"? GTF :"0.00"}
                            </div>

                      {username === "" || username === null ? (
                        <button
                          onClick={handlesignout}
                          name="booking"
                          class="search-btn"
                        >
                          Book Now{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          name="booking"
                          onClick={handleBooknow}
                          class="search-btn"
                        >
                          Book Now{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="tj-offers">
        <div class="row">
          {/* <!--Offer Box Content Start--> */}
          <div class="col-md-3 col-sm-6">
            <div class="offer-box">
              <img src="/images/offer-icon1.png" alt="" />
              <div class="offer-info">
                <h4>Best Price Guaranteed</h4>
                <p>A more recently with desktop softy like aldus page maker.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="offer-box">
              <img src="/images/offer-icon2.png" alt="" />
              <div class="offer-info">
                <h4>24/7 Customer Care</h4>
                <p>A more recently with desktop softy like aldus page maker.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="offer-box">
              <img src="/images/offer-icon3.png" alt="" />
              <div class="offer-info">
                <h4>Home Pickups</h4>
                <p>A more recently with desktop softy like aldus page maker.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="offer-box">
              <img src="/images/offer-icon4.png" alt="" />
              <div class="offer-info">
                <h4>Easy Bookings</h4>
                <p>A more recently with desktop softy like aldus page maker.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="tj-welcome">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-7">
              <div class="about-info">
                <div class="tj-heading-style">
                  <h3>Who We Are</h3>
                </div>
                <p>
                  Lorem Ipsum passages, and more recently with desktop
                  publishing software like aldus pageMaker including versions of
                  all the Lorem Ipsum generators on thet Internet tends to
                  repeat predefined chunks as necessary, making this an web
                  evolved over the years, sometimes by accident.
                </p>
                <Link to="/about-us">
                  See all Vehicles
                  <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                </Link>
                <ul class="facts-list">
                  <li>
                    <strong class="fact-count">100</strong>
                    <i class="fa fa-percent"></i>
                    <span>Happy Customer</span>
                  </li>
                  <li>
                    <strong class="fact-count">200</strong>
                    <i class="fas fa-plus"></i>
                    <span>Luxury Cars</span>
                  </li>
                  <li>
                    <strong class="fact-count">12,000</strong>
                    <i class="fas fa-arrow-up"></i>
                    <span>Kilometers Driven</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6 col-sm-5">
              <div class="welcome-banner">
                <img src="/images/car.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="fleet-carousel">
        <div class="col-md-12 col-sm-12">
          <div class="tj-heading-style">
            <h3>Our Car Fleet</h3>
          </div>
        </div>
      
        <div className="cab-carousel">
          <Slider
            dots={true}
            infinite={true}
            slidesToShow={2.5}
            slidesToScroll={2.5}
            arrows={true}
            centerMode={true}
            autoplay={true}
            speed={500}
            pauseOnHover={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {catDataForCarousel &&
              catDataForCarousel.map((cat, index) => {

                return (
                  <div class="fleet-item" key={index}>
                    <div className="fleet-image-container">
                      <img src={cat.cat_icon_path} alt="" />
                    </div>
                    <div class="fleet-inner">
                      <h4>{cat.cat_name}</h4>
                      <ul>
                        {/* <li>
                          <i class="fas fa-taxi"></i>Luxery
                        </li> */}
                        <li>
                          <i class="fas fa-user-circle"></i>
                          {cat.cat_max_size} Passengers
                        </li>
                        <li>
                          <i class="fas fa-taxi"></i>Fare per km :{" "}
                          {cat.cat_fare_per_km}
                        </li>
                        <li>
                          <i class="fas fa-taxi"></i>Fare per min :{" "}
                          {cat.cat_fare_per_min}
                        </li>
                        {/* <li>
                          <i class="fas fa-tachometer-alt"></i>
                          {cat.cat_fare_per_km}/{cat.cat_fare_per_min} MPG
                        </li> */}
                      </ul>
                      <strong class="price">
                        <span>Starts from</span> ${cat.cat_base_price}
                        {/* <span> / day</span> */}
                      </strong>
                      <a onClick={() => handleCarouselBookNow(cat)}  
                //      href={`/booknow?catData=${sliderCat}`}
                        
                      >
                        Book Now{" "}
                        <i
                          class="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </section>

      {/* <!--Cab Services Section Start--> */}
      <section class="cab-services">
        <div class="container">
          <div class="row">
            <div class="tj-heading-style">
              <h3>Our Services</h3>
              <p>
                Lorem Ipsum passages, and more recently with desktop publishing
                software like aldus pageMaker including versions.
              </p>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon1.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Restaurants</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon2.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Airports</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon3.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Hospitals</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon4.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Beaches</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon5.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Shopping Malls</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div class="cab-service-box">
                <figure class="service-thumb">
                  <img src="/images/cab-service-icon6.png" alt="" />
                </figure>
                <div class="service-desc">
                  <h4>Wedding Parties</h4>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Call To Action 2 Content Start--> */}
      <section class="tj-cal-to-action2">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-sm-9">
              <div class="cta-tagline">
                <h2>Incredible Destinations at Incredible Deals</h2>
              </div>
            </div>

            <div class="col-md-3 col-sm-3">
              <div class="cta-btn">
                <Link to="/booknow">
                  Book Now{" "}
                  <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Home;
