






import React, { useState, useEffect, useRef } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { logDOM } from "@testing-library/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ReserveRental = ({
  rentalTotalFair,
  setRentalPackage,
  setRentalTotalFair,
  setRentalBaseFair,
  setRentalTax,
}) => {
  const navigate = useNavigate();

  const userid = sessionStorage.getItem("userid");
  const today = dayjs();
  const futureTime = today.add(30, 'minute');
  const [time, settime] = useState(futureTime);

  // const today = dayjs();
  // const [time, settime] = useState(today);
  const [drop, setdrop] = useState("");
  const [dropValue, setDropValue] = useState("");
  const [address, setaddress] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const dropAutocompleteRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [pickupAddress, setPickupAddress] = useState("");
  const [droplatlng, setdroplatlng] = useState("");
  const [catdata, setcatdata] = useState(null);
  const [check, setcheck] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState("");
  const [packageKey, setPackageKey] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicleKey, setvehicleKey] = useState(null);
  const [error, seterror] = useState(false);
  const currentDate = new Date();
  const [categoryId, setCategoryId] = useState();
  const [packName, setPackName] = useState();
  const [mypack, setmypack] = useState();
  const [sorted,setSorted] = useState()
  const [totalFair,setTotalFair] = useState(null)

  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  const selectedTime = time.$d
    ? moment(time.$d).seconds(0).milliseconds(0)
    : moment(time).seconds(0).milliseconds(0);
  const currentTime = moment().seconds(0).milliseconds(0);
  const [select, setselect] = useState();

  const handleDateAndTime = (e) => {
    setselect(e.$d);
    settime(e.$d);
  };
  const handleClearDropSelection = () => {
    setDropValue("");
    setdrop("");
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    setcoord(l2);
    setDropValue(result[0].formatted_address);
    setdrop(value);
  };

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropValue
      ) {
        setdrop("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [drop, dropValue]);

  const onChangedrop = (e) => {
    setdrop(e);
    setDropValue("");
  };
  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  useEffect(() => {
    handleCat();
 
  }, []);
  useEffect(()=>{
    setcatdata(catdata?.sort((a, b) =>  a.category_id- b.category_id ))

  },[catdata])

  const handleCat = () => {
    fetch(`${process.env.REACT_APP_URL}/categoryapi/getcategoriesv1`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcatdata(data.response);
      });
  };

  useEffect(() => {
    myPackages();
  }, []);

  const myPackages = () => {
    let data = {
      city_id: process.env.REACT_APP_CITY_ID,
      user_id: userid,
    };

    fetch(`${process.env.REACT_APP_URL}/tripapi/estimatepackagetripfare`, {
      method: "POST",
      header: {
        Accept: "application/json",
        "content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPackages(data.response);
      });
  };

  const BookingForm = (e) => {
    e.preventDefault();
    if (Validation()) {
      // handleBooknow();
      handleBooknow();
    } 

    
  };

  const selectPackage = (item, i) => {
    setPackageKey(i);
    setmypack(item);
    setRentalPackage(item.name);
    setSelectedPackages(item.distance);
    if(vehicleKey==null){

      setvehicleKey(0);
      setSelectedVehicle('Mini');
      setCategoryId(2);
      setPackName(item?.name);
    }
    // chooseVehicle()
  };
  useEffect(() => {
    mypack?.Category.map((item, i) => {
      let index ;

      if (item?.cat_name == selectedVehicle) {
      index = i;
      
      setRentalTotalFair(mypack?.Category[index]?.est?.trip_pay_amount);
      setRentalBaseFair(mypack?.Category[index]?.est?.trip_base_fare);
      setRentalTax(mypack?.Category[index]?.est?.tax_amt);
      setTotalFair(mypack?.Category[index]?.est?.trip_pay_amount)
      }
    });
  }, [vehicleKey,packageKey]);
  const chooseVehicle = (item, i) => {
    setvehicleKey(i);
    setSelectedVehicle(item?.cat_name);
    setCategoryId(item?.category_id);
    setPackName(item?.name);
  };
  const Validation = () => {
    let result = true;

    if (
      address == "" ||
      select == "" ||
      selectedPackages == "" ||
      selectedVehicle == "" ||
      check == "" ||
      !selectedTime.isSameOrAfter(currentTime)
    ) {
      result = false;
      seterror(true);
    } else {
      return result;
    }
    return result;
  };

  const form = moment(select).format("YYYY-MM-DD");
  const ftime = moment(time).format("hh:mm A");
  const time_date = form + " " + ftime;
  const apikey = sessionStorage.getItem("key");

  var d = new Date(time_date);
  var utc_offset = d.getTimezoneOffset();
  d.setMinutes(d.getMinutes() + utc_offset);
  const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm");

  let GTF = "0";
  let ST = 0;

  const handleBooknow = (e) => {
    let data = {
      // trip_pay_amount: mypack.Category[vehicleKey]?.est.trip_pay_amount,
      trip_pay_amount: totalFair,
      trip_comp_commision: "",
      is_ride_later: "1",
      seats: "1",
      trip_distance: mypack.distance,
      trip_type: "rental",
      category_id: categoryId,
      tax_amt: ST,
      pkg_cat_name: packName,
      trip_scheduled_pick_lng: "",
      is_share: "0",
      trip_status: "request",
      trip_dunit: "km",
      cat_name: selectedVehicle,
      trip_from_loc: address,
      trip_driver_commision: "",
      trip_currency: "€",
      api_key: apikey,
      user_id: userid,
      pkg_cat_id: mypack.Category[vehicleKey]?.pkg_cat_id,
      trip_date: GMTtime,
      trip_total_time: mypack.hours,
      trip_base_fare: mypack.Category[vehicleKey]?.est.trip_base_fare,
      trip_scheduled_pick_lat: "",
      city_id: process.env.REACT_APP_CITY_ID,
    };

    fetch(`${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json().then((result) => {
        if (res.status == 200) {
          setselect(new Date());
          settime(time);
          setaddress("");
          setdrop("");
          seterror(false);
          toast.success("Your request has been saved successfully,");
          localStorage.setItem("tabValue", "bookinghistory");
          navigate("/profile");
        } else {
          console.log(result.message);
        }
      });
    });
    // }
  };

  return (
    <>
      <div>
        <form className="booking-frm" id="User-Form" onSubmit={BookingForm} style={{    padding: "44px 6px"}}>
          <div className="col-md-12 col-sm-12">
            <strong style={{ marginBottom: "-15px", marginTop:"-28px" }}>From</strong>
            <div className="field-holder border ">
              <PlacesAutocomplete
                value={address}
                onChange={onChangeAddress}
                onSelect={handleSelect}
                ref={autocompleteRef}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <span className="fas fa-map-marker-alt"></span>
                    <input
                      {...getInputProps({
                        placeholder: "Pickup Location",
                        className: "location-search-input",
                      })}
                      readOnly={addressValue}
                    />
                    {addressValue && (
                      <span
                        className="fas fa-times-circle"
                        style={{
                          marginRight: "20px",
                          fontSize: "11px",
                          cursor: "pointer",
                        }}
                        onClick={handleClearSelection}
                      ></span>
                    )}

                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            className="input-suggestion"
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                          >
                            <i class="material-icons">location_on</i>{" "}
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              {error && address.length <= 0 ? (
                <label
                  id="u_email-error"
                  className="error"
                  style={{
                    color: "#e74c3c",
                    fontWeight: "500",
                    fontSize: "11px",
                    marginTop: "-15px",
                  }}
                >
                 Please Select Pickup Location
                </label>
              ) : null}
            </div>
          </div>

          <div className="col-md-12 col-sm-12"   style={{ marginTop: "5px" }}>
            <strong style={{ marginBottom: "0" }}>Start Date</strong>
            <div className="field-holder">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={time}
                  disablePast
                  ampm
                  onChange={(e) => handleDateAndTime(e)}
                  slotProps={{
                    textField: {
                      error: false,
                      inputProps: { readOnly: true },
                    },
                  }}
                  format="DD/MM/YYYY hh:mm A"
                />
              </LocalizationProvider>
            </div>
{/*            
            {(error && !select) || select == currentDate ? (
              <label
                id="u_email-error"
                className="error"
                style={{
                  color: "#e74c3c",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginTop: "-10px",
                  marginBottom: "-2px",
                }}
              >
                Please select a future time
              </label>
            ) : null} */}
          </div>

          <div className="col-md-12 col-sm-12" style={{ marginTop: "10px" }}>
            <strong className="mt-5" style={{ marginBottom: "0" }} >Select package</strong>
            <div className="field-holder" style={{ display: "flex" }}>
              {packages?.map((item, i) => {
                return (
                  <div key={i}>
                    {/* <div className="pckborder" > */}
                    <div
                      className={`pckborder-reserve  ${
                        packageKey == i ? "selected" : ""
                      }`}
                      onClick={() => selectPackage(item, i)}
                    >
                      {/* <div className="pckborder" onclick={selectPackage(item.distance)}> */}
                      <div className="pack-name">{item.hours}hrs</div>
                      <div className="pack-name">{item.distance}km</div>
                    </div>
                  </div>
                );
              })}
            </div>

            {error && packageKey == null ? (
              <label
                id="u_email-error"
                className="error"
                style={{
                  color: "#e74c3c",
                  fontWeight: "500",
                  fontSize: "11px",
                  // marginTop: "-1px",
                  marginBottom: "-7px",
                }}
              >
                Please Select a package
              </label>
            ) : null}
          </div>

          {/* <div className="col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
            <strong className="mt-5">
              Package fare Details Estimation view Details
            </strong>
          </div> */}

          <div className="col-md-12 col-sm-12" style={{ marginTop: "19px" }}>
            <strong style={{ marginBottom: "0" }}>Category</strong>

            {/* <div className="pckborder"> */}
            <div style={{ display: "flex" }}>
              {catdata ? (
                <>
                  {catdata.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`catborder-reserve  ${
                            vehicleKey == i ? "selectvehicle" : ""
                          }`}
                          onClick={() => chooseVehicle(item, i)}
                        >
                          <div className="fleet-name">{item?.cat_name}</div>
                          <img
                            src={ catdata[i].cat_icon_path}
                            alt={"category image"}
                            className="catimg-reserve"
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
            {error && vehicleKey == null ? (
              <label
                id="u_email-error"
                className="error"
                style={{
                  color: "#e74c3c",
                  fontWeight: "500",
                  fontSize: "11px",
                  // marginTop: "-5px",
                  // marginBottom: "15px",
                }}
              >
                Please Select a Category
              </label>
            ) : null}
          </div>

           
          <div className="col-md-12 col-sm-12">  
                         <div
                           style={{
                             display: "flex",
                             alignItems: "start",
                             marginTop: 0,
                           }}
                         >
                           <input
                             name="book_terms-reserve"
                             fontSize="5px"
                             id="book_terms"
                             value={check}
                             type="checkbox"
                             onChange={(e) => setcheck(e.target.checked)}
                           />
                           <p className="ride-terms">
                             I understand and agree with the{" "}
                             <a
                               style={{ cursor: "pointer" }}
                               href="https://giovoyage.com/impressum"
                               target="_blank"
                               rel="noreferrer"
                             >
                               Terms
                             </a>{" "}
                             of Service and Cancellation{" "}
                           </p>
                         </div>
                         <label for="book_terms">
                           <input
                             type="hidden"
                             id="trip_distance"
                             name="distance"
                           />
                           {error && check == false ? (
                             <label
                               id="u_email-error"
                               className="error"
                               style={{
                                 color: "#e74c3c",
                                 fontWeight: "500",
                                 fontSize: "11px",
                               }}
                             >
                               Please accept terms and condition
                             </label>
                           ) : null}
                         </label>
                               {/* <b className="reserve_text" >
                                 Your chauffeur will wait 25 minutes free of charge
                               </b>{" "} */}

<div class="field-outer" style={{    background: "beige",
   height: "40px",
   textAlign:"center",
   padding: "12px",
   color: "black",
}}>
                  
                         {/* Book Now{" "} */}
                      <strong style={{color:"black"}}>    Estimated Fare: €{rentalTotalFair ? rentalTotalFair :"0.00"}</strong>
                      
                           </div>
                       </div>

          <div className="col-md-12 col-sm-12">
            <button className="book-btn" style={{width:"100%", marginTop:"7px"}}>
              Book Now{" "}
              <i
                className="fa fa-arrow-circle-rNclassNameight"
                aria-hidden="true"
              ></i>
            </button>
            {/* <button
                          type="submit"
                          name="booking"
                          onClick={handleBooknow}
                          class="book-btn"
                        >
                          Book Now{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                          ></i>
                        </button> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default ReserveRental;
